import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box, Col, Container, Flex, Row } from '../grid';
import { Button } from '../button';
import { CardListing } from '../card-listing';
import { CardListingSkeletonLoader } from '../card-listing-skeleton-loader';
import { cmsImagePropTypes } from '../../prop-types';
import { Divider } from '../divider';
import { FilterPicker } from '../filter-picker';
import { P } from '../typography';

const SHOW_MORE_LIMIT = 9;

const FilterGrid = ({ items, filters, loading, defaultFilter, cardComponent, onClick, imageDimensions }) => {
  const [filter, setFilter] = useState(defaultFilter);
  const [visibleItemsLimit, setVisibleItemsLimit] = useState(SHOW_MORE_LIMIT);

  useEffect(() => {
    setVisibleItemsLimit(SHOW_MORE_LIMIT);
  }, [filter]);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col width={{ xs: 12 / 12, lg: 8 / 12 }} offset={[0, 0, 0, 4 / 12]}>
            <CardListingSkeletonLoader cardWidth={{ xs: 2 / 12 }} hasHeading={false} height="3rem" itemCount={3} />
          </Col>
        </Row>
        <Row>
          <Col width={{ xs: 12 / 12, lg: 10 / 12 }} offset={[0, 0, 0, 1 / 12]}>
            <Divider my={1} />
            <CardListingSkeletonLoader
              cardWidth={{ xs: 12 / 12, lg: 4 / 12 }}
              hasHeading={false}
              height="12rem"
              itemCount={6}
            />
          </Col>
        </Row>
      </Container>
    );
  }

  const handleShowMore = () => setVisibleItemsLimit(visibleItemsLimit + SHOW_MORE_LIMIT);

  const filteredItems = items.filter(({ category }) => {
    if (filter === defaultFilter) {
      return true;
    }

    return filter === category;
  });

  const visibleItems = filteredItems.slice(0, visibleItemsLimit);

  return (
    <Box>
      <FilterPicker filters={filters} onClick={setFilter} selected={filter} />
      <Container>
        <Row px={2} mt={2} justifyContent="center">
          <Col width="100%">
            <Divider my={1} variant="darkGrey" />
            <CardListing
              items={visibleItems}
              CardComponent={cardComponent}
              cardWidth={{ xs: 12 / 12, md: 4 / 12 }}
              cardImageHeight={{ xs: '300px', lg: '400px' }}
              imageTransformIsRequired
              {...{ onClick, imageDimensions }}
            />
            <Flex mt={3} justifyContent="center" flexDirection="column" alignItems="center">
              <P mb="0.5rem" color="greyPrimary" textAlign="center">
                Showing {visibleItems.length} of {filteredItems.length} results
              </P>
              {filteredItems.length > visibleItems.length && (
                <Button variant="secondary" onClick={handleShowMore}>
                  Show More
                </Button>
              )}
            </Flex>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

FilterGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.arrayOf(cmsImagePropTypes),
      category: PropTypes.string,
    })
  ).isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool,
  defaultFilter: PropTypes.string.isRequired,
  cardComponent: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  imageDimensions: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

FilterGrid.defaultProps = {
  loading: false,
  onClick: undefined,
  imageDimensions: undefined,
};

export { FilterGrid };
