import { get } from 'lodash';
import gql from 'graphql-tag';
import trimStart from 'lodash/trimStart';

import { fetch } from '../../content/queries';
import { logger } from '../logger';

const fetchStaticRedirects = async uri => {
  const trimedUri = trimStart(uri, '/');
  const { siteId } = process.env.cms;

  const redirectQuery = gql`
    query getStaticRedirectsFromCMS {
      retour(siteId: ${siteId}, uri: "/${trimedUri}") {
        siteId
        enabled
        redirectDestUrl
        redirectHttpCode
      }
    }
  `;

  try {
    const { retour } = await fetch({ query: redirectQuery });

    // this is required due to bug in retour that returns redirects for wrong site
    if (get(retour, 'siteId') !== parseInt(siteId, 10)) {
      return null;
    }

    return retour;
  } catch (err) {
    logger.error('CMS: Failed to fetch redirects from CMS', err);
    return null;
  }
};

export { fetchStaticRedirects };
