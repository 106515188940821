import { colors } from '@nandosaus/constants';
import PropTypes from 'prop-types';

import { Decal } from '..';

const DecalIcon = ({ name, ...props }) => {
  return <Decal name={name} width="100%" height="100%" position={null} {...props} />;
};

DecalIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

const decals = {
  'peri-products': {
    left: <DecalIcon name="arrow" color="orange500" preserveAspectRatio="xMinYMid meet" />,
    right: <DecalIcon name="pepperHeart" preserveAspectRatio="xMaxYMid meet" />,
  },
  'peri-perks': {
    left: <DecalIcon name="doubleX" color="perksBlue" preserveAspectRatio="xMinYMid meet" />,
    right: (
      <DecalIcon
        name="flame"
        fillMainFlame={colors.periRed}
        fillSideFlame={colors.perksRed}
        preserveAspectRatio="xMaxYMid meet"
      />
    ),
  },
  '404': {
    left: <DecalIcon name="doubleX" color="green500" preserveAspectRatio="xMinYMid meet" />,
    right: <DecalIcon name="flame" preserveAspectRatio="xMaxYMid meet" />,
  },
};

decals['news-and-events'] = decals['peri-products'];
decals.recipes = decals['peri-products'];
decals.restaurants = decals['peri-products'];

export { decals };
