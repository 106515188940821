import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFeatureFlags } from '@utils/feature-flags';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../analytics';
import { Box, Col, Container, Row } from '../components/grid';
import { Button } from '../components/button';
import { decals } from '../components/decal/constants';
import { fetchStaticRedirects } from '../utils/fetch-static-redirects';
import { H1, H3, Subheading } from '../components/typography';
import { Layout } from '../components/layout';
import { navigation } from '../constants';
import { PageHeaderBlock } from '../components/content-image';

const pagesData = Object.freeze({
  pageNotFound: {
    title: 'Page not found',
    message: 'We couldn’t find the page you were looking for. Try double checking the address',
  },
  anErrorHasOcurred: {
    title: 'An error has occurred',
    message: 'Unfortunately, we have encountered an error. Please try again later',
  },
});

const Error = ({ statusCode, errorMessage, showRefreshButton, reload }) => {
  const pageData = statusCode === 404 ? pagesData.pageNotFound : pagesData.anErrorHasOcurred;
  const description = errorMessage ?? pageData.message;

  useEffect(() => {
    analytics.track(ANALYTICS_EVENTS.PAGE_ERROR, {
      text: pageData.title,
    });
  }, [pageData.title]);

  const colWidth = { xs: 12 / 12, md: 8 / 12, lg: 6 / 12 };
  const colOffset = [0, 0, 1 / 12, 3 / 12];

  return (
    <Layout title={pageData.title}>
      <PageHeaderBlock
        subheading={
          <Box mt={-1}>
            <H1>{statusCode || 'An error has occurred'}</H1>
            <Subheading mt={2}>{description}</Subheading>
          </Box>
        }
        leftDecal={decals['404'].left}
        leftDecalStyle={{ top: { xs: 0, md: 3 } }}
        rightDecal={decals['404'].right}
        rightDecalStyle={{ top: { xs: 0, md: 3 } }}
      />
      <StyledContainer mt={-3}>
        <Row flexWrap="wrap">
          {statusCode === 404 && (
            <Col mb={4} width={colWidth} offset={colOffset}>
              <H3 mb={1}>Hungry? Get ordering</H3>
              <StyledButton href={navigation.MENU}>Order Food</StyledButton>
            </Col>
          )}
          {showRefreshButton && (
            <Col mb={4} width={colWidth} offset={colOffset}>
              <StyledButton onClick={reload}>Refresh page</StyledButton>
            </Col>
          )}
        </Row>
      </StyledContainer>
    </Layout>
  );
};

const StyledContainer = styled(Container)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: auto;
`;

Error.getInitialProps = async ({ res, req, err }) => {
  const featureFlags = await getFeatureFlags();

  if (res && req) {
    const retour = await fetchStaticRedirects(req.url);
    if (retour && retour.enabled) {
      res.writeHead(retour.redirectHttpCode, {
        // TODO: discover why this step is necessary
        // redirectDestUrl includes extra `/` but only when called in this method
        Location: retour.redirectDestUrl.replace('//', '/'),
      });
      res.end();
      return {};
    }
  }

  if (res) {
    return { statusCode: res.statusCode, featureFlags };
  }

  if (err) {
    return { statusCode: err.statusCode, featureFlags };
  }

  return { statusCode: 404, featureFlags };
};

Error.propTypes = {
  errorMessage: PropTypes.string,
  statusCode: PropTypes.number,
  showRefreshButton: PropTypes.bool,
  reload: PropTypes.func,
};

Error.defaultProps = {
  errorMessage: null,
  statusCode: null,
  showRefreshButton: false,
  reload: undefined,
};

export default Error;
