const setCacheHeaders = ({ res, isPreview }) => {
  if (isPreview) {
    // No cache for preview
    res.setHeader('Cache-Control', 'no-store, no-cache, must-revalidate, proxy-revalidate');
    res.setHeader('Pragma', 'no-cache');
    res.setHeader('Expires', 0);
  } else {
    // Set 5 min cache to suppress DDos
    res.setHeader('Cache-Control', 'max-age=300');
  }
};

export { setCacheHeaders };
