import gql from 'graphql-tag';

import { fetch } from '../../content/queries';
import { logger } from '../logger';

export const getFeatureFlags = async () => {
  try {
    const { launchDarklyFeatureFlags: featureFlags } = await fetch({
      query: gql`
        query launchDarklyFeatureFlags($platform: String!) {
          launchDarklyFeatureFlags(platform: $platform) {
            name
            value
            type
          }
        }
      `,
      variables: { platform: 'Web' },
    });

    const processed = {};

    featureFlags?.forEach(({ name, value }) => {
      processed[name] = JSON.parse(value);
    });

    return {
      launchDarklyEnabled: true,
      flags: processed,
    };
  } catch (error) {
    logger.error(`Error initialising server side feature flags`, { error: error.errors });
  }

  return {};
};
