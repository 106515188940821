import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '../../constants';
import { Flex } from '../grid';
import { Label, P } from '../typography';
import { useIsMinWidth } from '../../hooks/use-is-min-width';

const FilterPicker = ({ filters, onClick, selected }) => {
  const isNotWeb = !useIsMinWidth('lg');

  return (
    <Container alignItems="center">
      {isNotWeb && (
        <Label mr={1} ml="1.5rem" color="greyPrimary">
          Filter
        </Label>
      )}
      {filters.map(filter => {
        const isSelected = filter === selected;

        return (
          <FilterButton onClick={() => onClick(filter)} type="button" key={filter} selected={isSelected}>
            <FilterOption data-testid={isSelected ? 'selected-filter-option' : 'filter-option'} selected={isSelected}>
              {filter}
            </FilterOption>
          </FilterButton>
        );
      })}
    </Container>
  );
};

const Container = styled(Flex)`
  overflow-x: auto;
  -ms-overflow-style: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.lg}) {
    justify-content: center;
  }
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border: ${({ selected, theme }) => `2px solid ${selected ? theme.colors.neonTeal400 : 'transparent'}`};
`;

const FilterOption = styled(P)`
  color: ${({ selected, theme }) => (selected ? theme.colors.black : theme.colors.greyPrimary)};
  white-space: nowrap;

  :not(:last-child) {
    margin-right: 1rem;
  }
`;

FilterPicker.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

export { FilterPicker };
