import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';
import times from 'lodash/times';

import { Box, Col, Row } from '../grid';
import { SkeletonLoader } from '../skeleton-loader';

// Styled components sit above component otherwise storybook crashes
const RotatedBox = styled(Box)`
  transform: rotate(-3deg);
`;

const CardListingSkeletonLoader = ({ cardWidth, hasHeading, height, itemCount }) => (
  <Row flexWrap="wrap" data-testid="card-listing-skeleton">
    {hasHeading && (
      <Col width="100%">
        <RotatedBox mt={4} mb={2} mx="auto" width="30%">
          <SkeletonLoader height="3rem" />
        </RotatedBox>
      </Col>
    )}

    {times(itemCount).map(item => (
      <Col key={`skeletonLoader-${item}`} width={cardWidth} mb={1}>
        <SkeletonLoader height={height} />
      </Col>
    ))}
  </Row>
);

CardListingSkeletonLoader.propTypes = {
  cardWidth: StyledSystemPropTypes.layout.width,
  hasHeading: PropTypes.bool,
  height: PropTypes.string,
  itemCount: PropTypes.number,
};

CardListingSkeletonLoader.defaultProps = {
  cardWidth: { xs: 12 / 12, md: 4 / 12 },
  hasHeading: true,
  height: '7rem',
  itemCount: 3,
};

export { CardListingSkeletonLoader };
