import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

import { useTheme } from '../../hooks/useTheme';

const SkeletonLoader = ({ height, width }) => {
  const { colors } = useTheme();

  return (
    <SkeletonTheme baseColor={colors.grey50} highlightColor={colors.grey100}>
      <Skeleton height={height} width={width} />
    </SkeletonTheme>
  );
};

SkeletonLoader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SkeletonLoader.defaultProps = {
  height: null,
  width: '100%',
};

export { SkeletonLoader };
